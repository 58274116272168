export function initTable() {
  initClickableRows();
}

function initClickableRows() {
  $("body").on("click", "tr[data-link-to]", function (e) {
    if (e.target.tagName.toLowerCase() === "a") {
      return;
    }
    e.preventDefault();

    window.location = $(this).attr("data-link-to");
  });
}
