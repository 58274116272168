import { sortChildrenByText } from "./sort_children_by_text";

/**
 * Check for a match against either the parent, or the child, of a grouped select2
 */
export function matchGroup(params, data) {
  // If there are no search terms, return all of the data
  if ($.trim(params.term) === "") {
    return data;
  }

  // Skip if there is no 'children' property
  if (typeof data.children === "undefined" || typeof data.text === "undefined") {
    return null;
  }

  // `data.children` contains the actual options that we are matching against
  var filteredChildren = [];
  $.each(data.children, function (idx, child) {
    if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
      filteredChildren.push(child);
    }
  });

  // Child match
  if (filteredChildren.length) {
    var modifiedData = $.extend({}, data, true);
    modifiedData.children = filteredChildren.sort(sortChildrenByText);
    return modifiedData;
  }

  // Parent match
  if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
    var modifiedData = $.extend({}, data, true);
    modifiedData.children = data.children.sort(sortChildrenByText);
    return modifiedData;
  }

  // Return `null` if the term should not be displayed
  return null;
}
